import { Box, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import bannerimg from '../../src/images/bannerimg.jpg'


const useStyles = makeStyles({
    banner__cont: {
        textAlign: 'left',
        fontFamily: 'Myriad Pro !important',
        lineHeight: '3rem !important',
        color: '#fff'
    },
    banner__cont_para: {
        textAlign: 'left',
        fontFamily: 'Myriad Pro !important',
        color: '#f2e1c3'
    },
    main__div: {
        position: 'relative',
    },
    mainbnr__c: {
        position: 'absolute',
        bottom: '10rem',
        left: '4.5rem'
    }
})

const Banner = () => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.main__div}>
                <Box component={'img'} src={bannerimg} width={'100%'} />
                <Box className={classes.mainbnr__c}>
                    <Typography variant="h5" className={classes.banner__cont}>
                        Improve breathing <Typography className={classes.br} component={'br'} />
                        Enhance respiratory benefit
                    </Typography>
                    <Typography className={classes.banner__cont_para}>Specializing in personalized tele-appointments<Typography className={classes.br} component={'br'} />
                        when breathing and respiration matter</Typography>
                </Box>
            </Box>
        </>
    )
}

export default Banner;