import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import techer from '../../src/images/techer.png'
import { Link } from "react-router-dom";
import btnbg from '../../src/images/btnbg.png'
import easyonlinebg from '../../src/images/easyonlinebg.png'
import bluewave from '../../src/images/bluewave .png'



const useStyles = makeStyles({


    main__div: {
        backgroundColor: '#abe1e5',
        padding: '7rem 0rem',
        '@media(max-width : 600px)': {
            padding: '8rem 0rem',
        }
    },
    p: {

        textAlign: 'left',
        '@media(max-width : 600px)': {
            textAlign: 'center'
        }
    },
    p3: {

        textAlign: 'left',
        fontSize: '13px !important',
        '@media(max-width : 600px)': {
            textAlign: 'center'
        }
    },
    br: {
        '@media(max-width : 600px)': {
            display: 'none !important'
        }
    },

})

const Footer = () => {
    const classes = useStyles();
    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Box className={classes.main__div}>
                    <Container>
                        <Box sx={{
                            width: '200px',
                            paddingLeft: '5rem',
                            '@media(max-width : 900px)': {
                                paddingLeft: '0rem',
                            }
                        }}>

                        </Box>
                    </Container>
                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                    }}>
                    <Box component={'img'} src={bluewave} width={'100%'} />
                </Box>
                <Box sx={{
                    position: 'absolute',
                    bottom: '2.5rem',
                    left: '20rem',
                    '@media(max-width : 1200px)': {
                        left: '6rem',
                        '@media(max-width : 900px)': {
                            left: '2rem',
                            '@media(max-width : 600px)': {
                                left: '1rem',
                                right: '1rem',
                                bottom: '1rem'
                            }
                        }
                    }
                }}>
                    <Box>
                        <Typography color={'#164e70'} className={classes.p}> © 2002-2024 Graduate School of Breathing Sciences,  LLC. | All rights reserved. <Box className={classes.br} component={'br'} /> RespiMatters-Institute for Applied Breathing Sciences<Typography fontSize={8} color={'#164e70'} component={'span'}>TM</Typography></Typography>
                    </Box>
                    <Box height={20} />
                    <Box>
                        <Typography color={'#164e70'} className={classes.p3}> *RespiMatters<Typography fontSize={8} color={'#164e70'} component={'span'}>TM</Typography> utilizes instrumentation that measures CO2 concentration in the alveoli of the lungs. This instrumentation is <Box className={classes.br} component={'br'} /> approved by the FCC, CE, UK CA.  This instrument is not verified by the FDA. Statements on this site are for educational purposes.</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Footer;