import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import brainimg from '../../src/images/brainimg.jpg'



const useStyles = makeStyles({
    main__div: {
        position: 'relative'
    },
    brain__cont: {
        textAlign: 'left',
        position: 'absolute',
        bottom: '5rem',
        left: '4.2rem',
        fontSize: '22px !important',
        '@media(max-width : 900px)': {
            bottom: '4rem',
            fontSize: '18px !important',
            left: '3.2rem',
            '@media(max-width : 600px)': {
                bottom: '1.4rem',
                left: '1.4rem',
                fontSize: '12px!important',
                textShadow: '0px 0px 0px black',
            }
        }
    },




})

const Brain = () => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.main__div}>
                <Box><Box component={'img'} src={brainimg} width={'100%'} /></Box>
                <Box>
                    <Typography className={classes.brain__cont} color={'#20471b'} > Essential organ function, such as the <Box component={'br'} /> brain benefit when breathing habits <Box component={'br'} /> are consistant with good respiration.</Typography>
                </Box>
            </Box>
        </>
    )
}

export default Brain;