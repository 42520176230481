import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import secondbg from '../../src/images/secondbg.png'
import lungs from '../../src/images/lungs.png'


const useStyles = makeStyles({
    main__div: {
        background: `url(${secondbg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        padding: '7rem 0rem 12rem 0rem',
        marginTop: '-5px',
        '@media(max-width : 600px)': {
            padding: '3rem 0rem 3rem 0rem'
        }
    },
    h6: {

        color: '#1480cb',
        textAlign: 'center',
        '@media(max-width : 600px)': {
            fontSize: '24px !important'
        }
    },
    lungsimg: {
        textAlign: 'center',
        paddingTop: '4rem'
    },
    ser__btm__cont: {
        paddingTop: '9rem',
        '@media(max-width : 600px)': {
            paddingTop: '2rem',
        }
    },
    br: {
        '@media(max-width : 600px)': {
            display: 'none'
        }
    },

})

const Services = () => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.main__div}>
                <Container>
                    <Box>
                        <Typography className={classes.h6} variant="h4">The Better You're Breathing <Typography className={classes.brt} component={'br'} /> The Better Your Respiration, <Typography className={classes.brt} component={'br'} />Performance, and Wellness. <Typography className={classes.brt} component={'br'} /> <Typography sx={{ marginTop: '1.5rem' }} className={classes.h6} variant="h4">RespiMatters<Typography fontSize={10} component={'span'}>TM</Typography> </Typography></Typography>

                    </Box>
                    <Box className={classes.lungsimg}>
                        <Box component={'img'} src={lungs} sx={{
                            '@media(max-width : 600px)': { width: '100%' }
                        }} />
                    </Box>
                    <Box className={classes.ser__btm__cont}>
                        <Typography className={classes.h6} variant="h4">We all learn habits, unconsciously and <Typography className={classes.br} component={'br'} /> continuously. This includes breathing habits <Typography className={classes.br} component={'br'} />that support us and others that don't.
                            <Typography sx={{ color: '#187e78' }} className={classes.h6} variant="h4">Learned breathing habits can compromise <Typography className={classes.br} component={'br'} /> wellness and performance without your <Typography className={classes.br} component={'br'} /> knowing it.
                            </Typography>
                            <Typography sx={{ marginTop: '1.5rem', }} className={classes.h6} variant="h4">Compromised breathing habits can bring on <Typography className={classes.br} component={'br'} /> symptoms and deficits that seriously affect <Typography className={classes.br} component={'br'} /> physical, emotional, mental, performance <Typography className={classes.br} component={'br'} /> and wellness. </Typography>
                        </Typography>
                    </Box>


                </Container>
            </Box>
        </>
    )
}

export default Services;



















