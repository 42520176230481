import { Box, Button, CircularProgress, Container, Grid,TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import techer from "../../src/images/techer.png";
import { Link } from "react-router-dom";
import btnbg from "../../src/images/btnbg.png";
import techarbg from "../../src/images/techarbg.png";
import threeofpeople from "../../src/images/threeofpeople.jpg";
import stepbgmain from "../../src/images/stepbgmain.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccesModal from "./SuccesModal";
import axios from "axios";
import { API_URL } from "./config";

const useStyles = makeStyles({
    error: {
        // marginTop: "0.5rem !important",
        fontSize: "14px !important",
        color: "red !important",
        textAlign: "start",
      },
  threeofp: {
    boxShadow: "0px 0px 12px -2px #16284c",
  },
  p: {
    marginTop: "2rem !important",
  },
  p2: {
    marginTop: "1rem !important",
    lineHeight: "1.3 !important",
  },
  p3: {
    marginTop: "0.5rem !important",
  },
  step__box: {
    textAlign: "center",
  },
  sch__btn: {
    background: `url(${btnbg})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",

    textDecoration: "none",
  },

  main__div: {
    padding: "1rem 0rem 8rem 0rem",
    background: `url(${stepbgmain})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    "@media(max-width : 900px)": {
      backgroundSize: "cover",
      "@media(max-width : 600px)": {
        padding: "2rem 0rem",
      },
    },
  },
  vdo: {
    width: "400px",
    "@media(max-width : 900px)": {
      width: "100%",
    },
  },

  br: {
    "@media(max-width : 900px)": {
      display: "none !important",
    },
  },
  bin1: {
    borderRadius: "8px",
    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
    border: "0",
    width: "100%",
    background: "transparent",
    color: "#fff",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&:focus-Visible": {
      outline: "none !important",
      border: "none !important",
    },
    "&:hover": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },

    "&:focus": {
      boxShadow: "none !important",
      border: "1px solid #fff !important",
      borderRadius: "10px !important ",
    },
    "& input": {
      padding: "10px 20px",
      width: "100%",
      color:"#000",
      "&:focus": {
        outline: "none",
      },
      "&::placeholder": {
        color: "#0000008f !important",
      },
    },
    "& textarea": {
      // background: "#F7F7F9",
      padding: "10px !important",
      color: "#800080",
      "&:focus": {
        outline: "none",
      },
      "&::placeholder": {
        color: "#0000008f",
      },
    },
  },
  sch__btn: {
    background: `url(${btnbg})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    textDecoration: 'none',
    color: '#fff',
    padding: '1.8rem 5rem'

},
});

const Teacher = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
 const getInitialValues = () => {
        return {
          email: "",
        };
      };
      const signup = async () => {
        setLoading(true)
        try {
          const res = await axios.post(
            `${API_URL}/subscribe/emails`,
            {
             email:formik.values.email
            }
          );
          if (res.data.status) {
              console.log(res);
              setLoading(false)
              setSuccess(true)
              formik.resetForm()
              setTimeout(() => {
                setSuccess(false) 
              }, 2000);
           
          } else {
            setLoading(false)
          }
        } catch (err) {
          setLoading(false)
        }
      };
 const validationSchema = Yup.object({
   
        email: Yup.string()
          .trim("Email should not contain spaces")
          .email("Invalid email address")
          .required("Email is required"),
     
     
      });
    const initialValues = getInitialValues();
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (
          values,
          { setErrors, setSubmitting, resetForm, errors }
        ) => {
          if (errors) {
            setErrors(errors);
          }else{
            signup()
          }
        },
      });
  const classes = useStyles();
  return (
    <>
      <Box className={classes.main__div}>
        <Box sx={{ textAlign: "center" }}>
          <video controls className={classes.vdo} loop>
            <source src="./video.mp4" type="video/mp4" />
          </video>
          {/* <Box component={'img'} src={techer} /> */}
        </Box>

        <Box sx={{ marginTop: "5rem" }}>
          <Box
            className={classes.threeofp}
            component={"img"}
            src={threeofpeople}
            width={"100%"}
          />
        </Box>

        <Box>
          <Box
            sx={{
              paddingTop: "4rem",
              paddingLeft: "5rem",
              "@media(max-width : 600px)": {
                paddingLeft: "1rem",
                paddingRight: "1rem",
                textAlign: "center",
              },
            }}
          >
            {/* <Typography variant="h4" color={'#23649b'}>About</Typography> */}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "-7rem",
          paddingLeft: "5rem",
          "@media(max-width : 900px)": {
            paddingRight: "1rem",
            "@media(max-width : 600px)": {
              paddingLeft: "0.6rem",
              paddingRight: "0.6rem",
              marginTop: "-2rem",
              textAlign: "center",
            },
          },
        }}
      >
        <Box>
          <Typography className={classes.p2} color={"#1480cb"}>
            <Typography component={"span"} fontSize={24}>
              Meet Sandra Reamer,
            </Typography>
            <Typography fontSize={13} component={"span"}>
              {" "}
              M.S., M.F.A, CBBA, CBBP, CSOM, CST.{" "}
              <Typography component={"br"} /> Master of Science in Applied
              Breathing Sciences, Master of Fine Arts and Education,
              <br />
              Teacher Credentialed, Impact Counselor, Orofacial Myology and
              Muscle Biofeedback Certified, <br />
              Breathing Behavior Analyst Certified, CranioSacral Therapist
              Certified, Founder of RespiMatters.com.
            </Typography>
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              width: "84%",
              "@media(max-width : 900px)": {
                width: "95%",
                "@media(max-width : 600px)": { width: "100%" },
              },
            }}
            className={classes.p}
            color={"#1480cb"}
          >
            {" "}
            Sandra is an international educator and one of the original pioneers
            of CapnoLearning ® a respiratory fitness approach and Breathing
            Behavior Analysis, an emerging field of study that brings together
            physiology, psychology, and biological sciences for improving
            wellness, performance, and competencies when good respiration
            matters. She is very active assisting people with identifying the
            behavioral contributions breathing can have when optimizing
            respiration and the associated benefits that can enhance cognitive,
            physical, emotional, and behavioral capabilities and competencies.
            She has a special interest in multidisciplinary collaboration,
            identifying problematic breathing habits that can lower physical and
            mental competency, and assisting people with learning breathing
            behaviors that enhance their physiology and psychology at all
            levels.
          </Typography>
        </Box>

        <Typography className={classes.p2} fontSize={24} color={"#1480cb"}>
          Make an Informed decision about improving your breathing and wellness.
        </Typography>

        <Box>
          <Typography className={classes.p3} color={"#1480cb"}>
            {" "}
            The choices you make regarding your breathing can be informed,
            specific, and directed to chart a new course{" "}
            <Box
              component={"br"}
              sx={{ "@media(max-width : 900px)": { display: "none" } }}
            />{" "}
            forward benefiting the intelligence of psychology when breathing and
            good respiration matters.
          </Typography>
          <Typography className={classes.p2} color={"#1480cb"}>
            {" "}
            RespiMatters
            <Typography fontSize={8} component={"span"}>
              TM
            </Typography>{" "}
            is here for you.
          </Typography>
        </Box>
      </Box>
        <Box p={"2rem"} textAlign={"center"} mt={"1rem"}>
          <Typography
            className={classes.p2}
            fontSize={24}
            fontFamily={"Candara"}
            
            color={"#1480cb"}
          >
            Join My Email Community
          </Typography>
          <form onSubmit={formik.handleSubmit}>
      <Box sx={{
        display:"flex",
        alignItems:"center",justifyContent:"center",gap:{sm:"1rem",xs:"0.5rem"},
        flexDirection:{sm:"row",xs:"column"}, mt:"1rem"
      }}>
      <Box sx={{
        width:{md:"40%",sm:"60%",xs:"100%"},
       
       }}>
       <TextField
                          autoComplete="off"
                          fullWidth
                          variant="standard"
                          type="email"
                          className={classes.bin1}
                          placeholder="Name@email.com"
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                 
       </Box>
    
                        <Button className={classes.sch__btn} type="submit" disableRipple sx={{
                            p:"1.5rem 2.5rem",
                            color:"#fff",
                            textTransform:"capitalize",
                            "&:hover":{
                                boxShadow:"none",
                                backgroundColor:"transparent"
                            }
                        }}>
                      {loading?      <CircularProgress
              sx={{
                width: "20px !important",
                height: "20px !important",
                color: "#e4dbe7",
              }}
            />:"Sign Up"}
                        </Button>
                        
                 
      </Box>
      {formik.touched.email && formik.errors.email && (
                          <Typography variant="body1" className={classes.error} textAlign={"center"}>
                            {formik.errors.email}
                          </Typography>
                        )}
      </form>
        </Box>
        <SuccesModal setSuccess={setSuccess} success={success} />
    </>
  );
};

export default Teacher;
