import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import stepimg1 from '../../src/images/stepimg1.png'
import stepimg2 from '../../src/images/stepimg2.png'
import stepimg3 from '../../src/images/stepimg3.png'
import { Link } from "react-router-dom";
import btnbg from '../../src/images/btnbg.png'




const useStyles = makeStyles({

    p: {
        fontSize: '19px !important',
        marginTop: '1.5rem !important',
        '@media(max-width : 1200px)': {
            fontSize: '18px !important',
            '@media screen and (min-width: 900px) and (max-width: 987px)': {
                fontSize: '15px !important',
            }
        }
    },

    step__box: {
        textAlign: 'center',
        boxShadow: '0px 0px 11px 3px #0c24654d',
        padding: '2rem 0rem',
        height: '300px',
        backgroundColor: '#fff',
        '@media(max-width : 900px)': {
            margin: '0rem 6rem',
            height: 'auto',
            '@media screen and (min-width: 200px) and (max-width: 622px)': {

                margin: '0rem 0rem',
                '@media(max-width : 600px)': {
                    padding: '2rem 1rem',
                    margin: '0rem 1rem',
                }
            }
        }
    },
    sch__btn: {
        background: `url(${btnbg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',

        textDecoration: 'none',
        color: '#fff',
        padding: '1.8rem 5rem'

    },
    btnbg: {
        marginTop: '4rem'
    },
    main__div: {
        padding: '6rem 4rem 8rem 4rem',
        textAlign: 'center',
        '@media(max-width : 600px)': {
            padding: '2rem 0rem',

        }
    },
    // br: {
    //     '@media(max-width : 600px)': {
    //         display: 'none !important'
    //     }
    // }


})

const StepConsultation = () => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.main__div}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className={classes.step__box}>
                                <Box component={'img'} src={stepimg1} />
                                <Typography variant="h6" color={'#20906c'}>STEP 1</Typography>
                                <Typography className={classes.p} color={'#000'}>Schedule a Consultation <Box className={classes.br} component={'br'} /> <Typography color={'#1480cb'} component={'a'} sx={{ textDecoration: 'none' }} href="https://booking.respimatters.com/" target="_blank">(Click here to get started)</Typography>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className={classes.step__box}>
                                <Box component={'img'} src={stepimg2} />
                                <Typography variant="h6" color={'#20906c'}>STEP 2</Typography>
                                <Typography className={classes.p} color={'#000'}> Complete & return initial forms. <Box className={classes.br} component={'br'} /> Check email regularly.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Box className={classes.step__box}>
                                <Box component={'img'} src={stepimg3} />
                                <Typography variant="h6" color={'#20906c'}>STEP 3</Typography>
                                <Typography className={classes.p} color={'#000'}> Initial discovery, <Box className={classes.br} component={'br'} /> followed by personalized <Box className={classes.br} component={'br'} /> learning tutorials.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className={classes.btnbg}>
                        <Link className={classes.sch__btn} to='https://booking.respimatters.com/' target="_blank">
                            Schedule today
                        </Link>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default StepConsultation;