import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { API_URL } from './config';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Keyboard, Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
// import { Autoplay } from 'swiper';

const modules = [Keyboard, Navigation, Pagination, Scrollbar, Autoplay];


const useStyle = makeStyles({
    mainbox: {
        flexGrow: 1,
        position: 'relative'
    },
    imgbox: {


    },
    mainbnr__c: {
        textAlign: 'left',
        position: 'absolute',
        bottom: '3rem',
        left: '3rem',
        zIndex: '100',
        '@media(max-width : 900px)': {
            left: '3rem',
            '@media(max-width : 600px)': {

                bottom: '1.4rem',
                left: '2.3rem',
            }
        }
    },
    br: {

    }

})




function BannerSlider() {

    const classes = useStyle();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [data, setData] = React.useState([])
    const maxSteps = data?.length;

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    React.useEffect(() => {
        getslot()
    }, [])

    const getslot = () => {

        fetch(API_URL + "/get/all/slider/image",

            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                },
            }

        ).then((response) => {
            if (response.status === 200) {
                response.json().then((resp) => {
                    let _temp = [];
                    resp.data.map((item, i) => {
                        // console.log("response data slider", item)
                        _temp.push({
                            // name:item.name.length === 0 ? "NA":item.name,
                            // description:item.description.length === 0 ? "NA":item.description,
                            image: item.s3_link.length === 0 ? "NA" : item.s3_link,
                        })
                        return 0
                    })

                    setData(_temp);


                });
            }

            else {
                console.log("network error")
            }


        })


    }


    return (
        <Box className={classes.mainbox}>
            <Swiper
                modules={modules}
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                steps={maxSteps}
                activeStep={activeStep}
                autoHeight={true}
                centeredSlides={false}
                slidesPerGroupSkip={4}
                grabCursor={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}

                keyboard={{
                    enabled: true,
                }}

                slidesPerView={1}
                spaceBetween={30}

                breakpoints={{

                    1024: {
                        slidesPerView: 1,

                    },

                    768: {
                        slidesPerView: 1,

                    },
                    640: {
                        slidesPerView: 1,

                    },

                }}

                navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled"
                }}
                pagination={{
                    clickable: true,
                    bulletClass: `swiper-pagination-bullet swiper-pagination-testClass`
                }}
            // modules={[Keyboard, Navigation, Pagination]}


            >
                {data?.map((v, id) => {
                    return (
                        <>
                            <SwiperSlide style={{ '@media(max-width:600px)': { width: '100% !important' } }}>
                                <Box

                                >
                                   {v.image? <Typography component={'img'} src={v.image} width={'100%'} sx={{height:{md:"100%",sm:"100%",xs:"220px"}}} />:<Typography sx={{height:{md:"400px",sm:"300px",xs:"250px"}}}/>}


                                </Box>
                            </SwiperSlide>
                        </>
                    )
                })}

            </Swiper>


           {data?.length>0 && <Box className={classes.mainbnr__c} sx={{
                "& p":{
                    color:"rgb(233 233 205)",
                    textShadow: '0px 2px 2px black',
                }
            }}>
                {/* <Typography sx={{ textShadow: '0px 2px 2px black', '@media(max-width : 600px)': { fontSize: '1.1rem', textAlign: 'left' } }} color={'#feecba'} variant="h4" className={classes.banner__cont}>

                Improve Breathing. <Typography sx={{ '@media(max-width : 600px)': { fontSize: '1.1rem' } }} component={'span'} color={'#fff'} variant="h4">  <Box className={classes.br} component={'br'} /> Enhance Wellness.
                    </Typography>
                </Typography> */}
                <Typography sx={{fontSize:{md:"2.5rem",xs:"1.3rem"},}}>CapnoLearning<Typography component={"sup"} sx={{fontSize:{md:"18px",xs:"14px"},fontWeight:"600"}}>®</Typography> Services</Typography>
                <Typography sx={{fontSize:{md:"1.5rem",xs:"0.8rem"},pt:{sm:"0.3rem",xs:"0rem"}}}>PERSONALIZED COACHING</Typography>
                <Typography sx={{fontSize:{md:"1.5rem",xs:"0.8rem"}}}>STATE-OF-THE-ART CO2 TECHNOLOGY</Typography>
                <Typography sx={{fontSize:{md:"1.5rem",xs:"0.8rem"}}}>UNPARALLELED BENEFITS</Typography>
                <Typography sx={{fontSize:{md:"1.2rem",xs:"0.7rem"},pt:{sm:"0rem",xs:"0rem"}}} component={'p'} color={'#fff'}>   We are the experts of CapnoLearning<Typography component={"sup"} sx={{fontSize:{md:"16px",xs:"12px"},fontWeight:"600"}}>®</Typography> </Typography>
                <Typography sx={{fontSize:{md:"1.2rem",xs:"0.7rem"}}} component={'p'} color={'#fff'}> & Breathing Behavior Analysis. </Typography>
                <Typography sx={{pt:"0.2rem",fontSize:"0.8rem", '@media(max-width : 600px)': { fontSize: '0.5rem' } }} fontWeight={600} component={'p'} color={'#ffffffad'} >   www.CapnoLearning.org
                    </Typography>
              
                {/* <Typography sx={{ textShadow: '0px 2px 2px black', fontSize: '0.8rem', '@media(max-width : 600px)': { textAlign: 'center' } }} color={'#f2e1c3'} fontFamily={'Myriad Pro !important'} className={classes.banner__cont_para}>Specializing in personalized tele-appointments<Typography className={classes.br} component={'br'} />
                when breathing and respiration matter</Typography> */}
            </Box>}

{/* 
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    zIndex: '100',
                    marginLeft: {sm:'1rem',xs:"0.2rem"},
                    transform: 'translateY(-50%)',
                }}
                className="swiper-button image-swiper-button-prev">

                <Button data-aos="flip-left" sx={{
                    backgroundColor: '#fff9',
                    minWidth: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    p:"0px",
                    boxShadow: '0px 0px 12px -2px #000000a1',
                    marginRight: {sm:'1rem',xs:"0.2rem"},
                    '&:hover': {
                        backgroundColor: '#fff',
                    },
                    '@media(max-width : 600px)': {
                        minWidth: '30px',
                        height: '30px',
                        marginRight: '1rem',
                        padding: '0px !important'

                    }
                }} className={classes.arrowbg}
                    onClick={handleBack} disabled={activeStep === 0}
                >
                    {theme.direction === 'rtl' ? (

                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </Button>
            </Box>



            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    marginRight: {sm:'1rem',xs:"0.2rem"},
                    transform: 'translateY(-50%)',
                    zIndex: '100',

                }}
                className="swiper-button image-swiper-button-next">
                <Button data-aos="flip-right" sx={{
                    backgroundColor: '#fff9',
                    minWidth: '30px',
                    height: '30px',
                    borderRadius: '5rem',
                    p:"0px",
                    boxShadow: '0px 0px 12px -2px #000000a1',
                    marginLeft: {sm:'1rem',xs:"0.2rem"},
                    '&:hover': {
                        backgroundColor: '#fff',
                    },
                    '@media(max-width : 600px)': {
                        minWidth: '30px',
                        height: '30px',
                        marginLeft: '1rem',
                        padding: '0px !important'

                    }
                }} className={classes.arrowbg} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                    {theme.direction === 'rtl' ? (

                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </Button>
            </Box> */}
        </Box>
    );
}

export default BannerSlider;