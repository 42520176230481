import React, { useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import { Keyboard, Navigation, Pagination, Scrollbar, Autoplay } from 'swiper/modules';
import { Box, Button, Typography } from '@mui/material';



import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';




const useStyles = makeStyles({
    arrowBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',


    },
    arrowbg: {
        backgroundColor: '#fff',
        padding: '10px',
        borderRadius: '5rem',
        height: '30px',
        width: '30px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        boxShadow: '0px 0px 12px -2px #00000029',
        cursor: 'pointer'
    },
    c_box_one: {
        display: 'inline-block',
        width: '240px',
        borderRadius: '8px 70px 8px 8px',
        padding: '0rem 0rem 2rem 0rem',
        textDecoration: 'none',
        backgroundColor: '#fff',
        color: '#000',
        '@media(max-width : 1200px)': {
            width: '220px',
            '@media(max-width : 900px)': {
                width: '300px',
            }
        }


    },
    c_box_two: {
        display: 'inline-block',
        width: '240px',
        borderRadius: '8px 70px 8px 8px',
        padding: '0rem 0rem 2rem 0rem',
        textDecoration: 'none',
        backgroundColor: '#fff',
        color: '#000',
        '@media(max-width : 1200px)': {
            width: '220px',
            '@media(max-width : 900px)': {
                width: '300px',
            }
        }

    },
    imgicon: {
        marginTop: '-4rem !important'
    },
    imgicon2: {
        marginTop: '0rem !important'
    },


    more_butn: {
        marginTop: '2rem !important',
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    },
    scroll: {
        position: 'absolute',
        top: '3.9rem',
        left: '5rem',
        right: '5rem',


    },
    swiperbox: {
        // backgroundColor: '#fff',
        // padding: '1rem 2rem',
        // boxShadow: '0px 0px 12px -2px #00000075',
        margin: '0rem 7rem',
        '@media(max-width : 1200px)': {
            margin: '0rem 4rem',
            '@media(max-width : 600px)': {
                margin: '0rem',
            }
        }
    },
    p: {
        fontSize: '16px !important',
        fontStyle: 'italic !important',

    },
    p2: {
        fontSize: '16px !important',
        fontStyle: 'italic !important',
        marginTop: '2.5rem !important',
    },

})



const SliderBox = [
    {
        id: 1,
        Title: "“My health provider referred me for these services because my breathing was not supporting my respiration based on testing at his office. I assumed that just because I was breathing that was enough. I am happy to say I was wrong. My breathing and respiration improved in more ways than I ever could have imagined working with Sandra. I discovered breathing could be easy rather than difficult. My provider said my airway behavior improved. The anxiety I was experiencing lifted. Sleep became my friend rather than a tussle. After 3 years of not being able to be physically active at all, I can chase my dog again. The physical pain I experienced was reduced more and more, then disappearing. The changes I’ve made have returned me to myself. I’m well on my way to feeling better, more active, and freer from the debilitating challenges I was experiencing. These services have helped me immensely.”",
        Paragraph: "(JS, high school student)"
    },
    {
        id: 2,
        Title: "“These services saved my marriage and made it possible for me to overcome overwhelming feelings of anxiety. I am looking forward to continuing applying what I‘ve learned to improve my breathing in more activities. No more having to make my life smaller because of feeling anxious. I am available to myself, my husband, and children in ways I haven’t been able to be in years. I can do more and be more active and available to my family. I thank you and my husband thanks you.”",
        Paragraph: "(J.S., US military Sergeant)"
    },

    {
        id: 3,
        Title: "“I thought my pregnancy caused the nausea, chest tension, breathlessness, and fatigue I was experiencing. During my pregnancy, I scheduled for an analysis and found that I was not breathing optimally. I scheduled for learning services to see if my breathing habits could reduce the nausea, chest tension, breathlessness, and fatigue that I thought were because of my pregnancy. I was not only able to reduce these concerns I was able to stop them from occurring. Sandra really cares about the people who receive breathing services from her and that reflects in how welcome and comfortable you feel around her. I highly recommend working with her, not just for her care, because she truly has your best interests at heart.”",
        Paragraph: "(RR, mom, and physical therapist)"
    },
    {
        id: 4,
        Title: "“I wanted to go to the source where Breathing Behavior Analysis was pioneered so I was looking forward to working with Sandra directly. I thought good breathing was about relaxation and what I learned about my own respiration during the analysis and tele-learning sessions helped me shift away from that belief and the physical discomfort I was experiencing. My main goal was to improve my fitness and see if I could stop some uncomfortable physical concerns. I’ll spare you the details, but some discomfort showed up during meditation, sleep, and exercise. Chest and neck tension, racing heart, running out of air too soon during workouts, lower endurance. I felt worried because I felt physical concerns that were showing up more often. I scheduled for learning sessions because I love to excel in fitness, and I thought that I should be making more gains given my regular training. For me, these services took my fitness to the next level. The analysis service helped me to figure out what breathing habits I wanted to improve. The one-on-one sessions helped me to discover what I could learn to improve my respiratory chemistry and take what I was learning into my workouts and meditations between appointments. I was able to address all my goals just with breathing habit changes. These fantastic services delivered in a compassionate manner helped me take my fitness to the next level. I will be able to apply what I’ve learned to help me excel in all kinds of ways. I could not be happier.”",
        Paragraph: "(J.B., business executive, fitness enthusiast)"
    },

]


const Testimonial = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = SliderBox.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <>
            <Box sx={{
                position: 'relative',
                padding: '3rem 3rem 0rem 3rem',
                '@media(max-width : 600px)': {
                    padding: '2rem 0rem 0rem 0rem'
                }
            }}>
                <Swiper
                    className={classes.swiperbox}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    steps={maxSteps}
                    activeStep={activeStep}
                    autoHeight={true}
                    centeredSlides={false}
                    slidesPerGroupSkip={4}
                    grabCursor={true}
                    keyboard={{
                        enabled: true,
                    }}

                    slidesPerView={1}
                    spaceBetween={30}

                    breakpoints={{

                        1024: {
                            slidesPerView: 1,

                        },

                        768: {
                            slidesPerView: 1,

                        },
                        640: {
                            slidesPerView: 1,

                        },

                    }}

                    navigation={{
                        nextEl: ".image-swiper-button-next",
                        prevEl: ".image-swiper-button-prev",
                        disabledClass: "swiper-button-disabled"
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Keyboard, Navigation,]}


                >
                    {SliderBox.map((v, id) => {
                        const { Title, Paragraph } = v
                        return (
                            <>
                                <SwiperSlide key={id} style={{ '@media(max-width:600px)': { width: '100% !important' } }}>
                                    <Box
                                        sx={{

                                            borderRadius: '12px',
                                            textAlign: 'center',

                                            '@media(max-width:600px)': {
                                                textAlign: 'center'
                                            }
                                        }}
                                    >
                                        <Typography className={classes.p} color={'#3288bd'}>{Title}</Typography>
                                        <Typography className={classes.p2} color={'#3288bd'}>{Paragraph}</Typography>

                                    </Box>
                                </SwiperSlide>
                            </>
                        )
                    })}

                </Swiper>



                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '2rem',
                        zIndex: '100',

                        '@media(max-width : 1200px)': {
                            left: '3rem',
                            '@media(max-width : 600px)': {
                                top: '105%',
                                left: '7rem',
                            }
                        }
                    }}
                    className="swiper-button image-swiper-button-prev">

                    <Button data-aos="flip-left" sx={{
                        backgroundColor: '#fff0',
                        padding: '10px',
                        color: '#1480cb',
                        border: '1px solid #1480cb',
                        borderRadius: '5rem',
                        height: '40px',
                        width: '40px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        boxShadow: 'inherit',
                        cursor: 'pointer',
                        minWidth: '40px',
                        '&:hover': {
                            backgroundColor: '#1480cb',
                            color: '#fff'
                        },
                        '@media(max-width : 600px)': {
                            minWidth: '36px',
                            height: '36px',
                            width: '36px',
                        }
                    }} className={classes.arrowbg}
                        onClick={handleBack} disabled={activeStep === 0}
                    >
                        {theme.direction === 'rtl' ? (

                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                    </Button>
                </Box>



                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: '2rem',
                        zIndex: '100',

                        '@media(max-width : 1200px)': {
                            right: '3rem',
                            '@media(max-width : 600px)': {
                                top: '105%',
                                right: '7rem',
                            }
                        }
                    }}
                    className="swiper-button image-swiper-button-next">
                    <Button data-aos="flip-right" sx={{
                        backgroundColor: '#fff0',
                        padding: '10px',
                        color: '#1480cb',
                        border: '1px solid #1480cb',
                        borderRadius: '5rem',
                        height: '40px',
                        width: '40px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        boxShadow: 'inherit',
                        cursor: 'pointer',
                        minWidth: '40px',
                        '&:hover': {
                            backgroundColor: '#1480cb',
                            color: '#fff'
                        },
                        '@media(max-width : 600px)': {
                            minWidth: '36px',
                            height: '36px',
                            width: '36px',
                        }
                    }} className={classes.arrowbg} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {theme.direction === 'rtl' ? (

                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                </Box>
            </Box>

        </>
    )
}

export default Testimonial;